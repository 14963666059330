<template>
 <v-container style="max-width: 1200px" v-if="isAccount">
 
   <v-overlay absolute color="transparent" v-if="loading">
      <v-progress-circular size="50" indeterminate color="primary"></v-progress-circular>
   </v-overlay>
   <v-img :src="images[doc.type]" :height="300" class="rounded" v-if="doc" :position="breakpoint.xs ? '45% 0px' : '0px 0px'">
     
      <v-btn  dark  absolute  depressed color="primary" rounded class="ma-3" @click="editEvent" v-if="doc" style="z-index:9999">
        <v-icon  color="mx-1">mdi-pencil</v-icon>
        {{ 'edit' | t}}
      </v-btn>
     <v-overlay absolute color="rgba(0,0,0,1)">
            <h1 class="text-center my-6">
              
              {{ doc.type | t }}
              
              </h1>
            <h2 class="text-center"> 
              <v-chip v-if="doc.type != 'wedding'" large outlined style="border-width: 2px; border-color: rgba(255,255,255,0.7)">
                <v-icon size="25" class="mx-2">mdi-crown</v-icon>
                {{doc.name}}
                <v-icon size="25" class="mx-2">mdi-crown</v-icon>

              </v-chip>
               <v-chip large outlined style="border-width: 1px; border-color: rgba(255,255,255,0.7)" v-if="doc.type == 'wedding'" >
                {{doc.me}}
              <v-icon size="35" class="mx-4"  v-if="doc.type == 'wedding'">mdi-heart</v-icon>
                {{doc.partner}}
              </v-chip>
            </h2>
            <v-row class="countdown ma-0 mx-auto my-0" style="width: 350px" v-if="!dateCompleted">
              <v-col >
              <fieldset class="rounded headline text-center" >
                <legend><span class="caption mx-1">{{'seconds' | t }}</span></legend>
                {{ nowDate.seconds }}
              </fieldset>
              </v-col>
               <v-col>
                 <fieldset class="rounded headline text-center" >
                <legend><span class="caption mx-1">{{'minuts' | t }}</span></legend>
                {{ nowDate.minutes }}
              </fieldset>
              </v-col>
               <v-col>
                 <fieldset class="rounded headline text-center" >
                <legend><span class="caption mx-1">{{'hours' | t }}</span></legend>
                {{ nowDate.hours }}
              </fieldset>
              </v-col>
               <v-col>
                 <fieldset class="rounded headline text-center" >
                <legend><span class="caption mx-1">{{'days' | t }}</span></legend>
                {{ nowDate.days }}
              </fieldset>
              </v-col>
            </v-row>
            <v-alert v-else rounded text  class="my-6 " icon="mdi-check">
              <div class="white--text headline">
                {{ 'event_completed' | t }}
              </div>
            </v-alert>
            
     </v-overlay>
   </v-img>
    <v-row class="mx-auto my-6">
      <v-col cols="6" sm="3" class="d-flex justify-center"  v-for="(sts, n) in status" :key="n" >
        <v-chip dark  outlined :color="sts.color || 'grey'"  class="pill headline d-flex align-center justify-center flex-column">
          <div class="headline">
            {{counts[sts.value]}}
          </div>
          <div class="caption">
            {{ sts.text | t }}
          </div>
        </v-chip>
      </v-col>
    </v-row>
    
   <v-row>
        <v-col cols="12"  sm="6">
          <v-img :src="guest" dark  max-height="200" class="rounded primary pa-8">
            <div class="headline">
              {{ 'guests_number' | t }}
            </div>
            <h1 style="font-size:4rem; max-width:50%" class="text-center">{{guests_count}}</h1>
          </v-img>
        </v-col>
         <v-col cols="12"  sm="6">
          <v-img :src="seats" dark max-height="200" class="rounded primary pa-8">
             <div class="headline">
              {{ 'tables_count' | t }}
            </div>
            <h1 style="font-size:4rem; max-width:50%" class="text-center">{{tables_count}}</h1>

          </v-img>
        </v-col>
         <v-col cols="12"  sm="6">
          <v-img :src="seat_empty" dark  max-height="200" class="rounded primary pa-8">
            <div class="headline">
              {{ 'empty_seats' | t }}
            </div>
            <h1 style="font-size:4rem; max-width:50%" class="text-center">{{tables_counts.empty}}</h1>
          </v-img>
        </v-col>
         <v-col cols="12"  sm="6">
          <v-img :src="seated" dark max-height="200" class="rounded primary pa-8">
             <div class="headline">
              {{ 'seated_seats' | t }}
            </div>
            <h1 style="font-size:4rem; max-width:50%" class="text-center">{{tables_counts.taken}}</h1>

          </v-img>
        </v-col>
      </v-row>

 </v-container>
</template>

<script>
const guest = require('@/assets/guests.png')
const seats = require('@/assets/seats.png')
const seated = require('@/assets/seated.png')
const seat_empty = require('@/assets/seat_empty.png')

let interval, unsub;

export default {

  data: () => ({
    isAccount:false,
    guest,
    seats,
    seated,
    seat_empty,
    loading: false,
    doc: null,
    now: new Date(),
    guests_count: 0,
    counts: {
      pendding: 0,
      denied: 0,
      confiremed: 0,
      maybe: 0
    },
    tables_count: 0,
    tables_counts: {  
      empty: 0,
      taken: 0
    },

    images: {
      wedding:  require('@/assets/wedding-bg.png'),
      birthday:  require('@/assets/birthday-bg.png'),
      personal:  require('@/assets/personal-bg.png'),
      business:  require('@/assets/business-bg.png'),
    },
    status: [
      {
        text: 'confiremeds',
        value: 'confiremed',
        color: 'success'
      },
     
      {
        text: 'maybes',
        value: 'maybe',
        color: 'warning'
      },
       {
        text: 'denieds',
        value: 'denied',
        color: 'error'
      },
      {
        text: 'waiting_confirmation',
        value: 'pendding',
      }
    ]

  }),
  watch: {
    $route: {
      handler:  'update',
      immediate: true
    }
  },
  computed: {
    eventDocRef () {
      return this.database.collection('event').doc(this.currentUser.uid)
    },
    aggregationRef () {
       return this.database.collection('users').doc(this.currentUser.uid)
    },
    dateCompleted () {
      return (this.eventDate - this.now) <= 0
    },
    nowDate() {
      function pad(num, size) {
          num = num.toString();
          while (num.length < size) num = "0" + num;
          return num;
      }
   
      // get total seconds between the times
      let delta = Math.abs(this.eventDate - this.now) / 1000;

      // calculate (and subtract) whole days
      let days = Math.floor(delta / 86400);
      delta -= days * 86400;

      // calculate (and subtract) whole hours
      let hours = Math.floor(delta / 3600) % 24;
      delta -= hours * 3600;

      // calculate (and subtract) whole minutes
      let minutes = Math.floor(delta / 60) % 60;
      delta -= minutes * 60;

      // what's left is seconds
      let seconds = Math.round(delta % 60);  // in theory the modulus is not required
      return {
        days: pad(days, 2), hours: pad(hours, 2), minutes: pad(minutes, 2), seconds: pad(seconds, 2)
      }
    },
    eventDate () {
      const [h, m] = this.doc.time.split(':').map(e=>Number(e))
      const date = this.doc.date.toDate()
      date.setHours(h)
      date.setMinutes(m)
      return date
    }
  },
  methods: {

    update () {
      if (this.hasRoles('admin')) return;

      if (unsub)
        unsub()
      this.loading = true
      unsub = this.aggregationRef.onSnapshot((snap) => {
        const { total_guest, status_pendding, status_maybe, status_denied, status_confiremed, tables , total_seats, taken_seats } = snap.data()
        this.counts.pendding = status_pendding || 0
        this.counts.denied = status_denied || 0
        this.counts.confiremed = status_confiremed || 0
        this.counts.maybe = status_maybe || 0
        this.guests_count = total_guest || 0

        // table
        this.tables_count =  tables || 0
        this.tables_counts.empty = (total_seats || 0) - (taken_seats || 0)
        this.tables_counts.taken = taken_seats || 0

      }, this.handelError)


      this.eventDocRef.get().then(doc => {
          this.doc = doc.exists ? doc.data() : null
          if (!doc.exists)
            this.editEvent()
      }).catch(this.handelError).finally(() => this.loading = false)

      

    },
    editEvent () {
      const vm = this
      this.openDialog('event', {
          title: 'event_details',
          confirmText: 'save',
          confirmIcon: 'mdi-check',
          color: 'primary',
          values: vm.doc,
          showCancel: !!this.doc
      }, (data) => {
        console.log(data);
          vm.dialogLoading(true)
          vm.eventDocRef.set({...data})
              .then(() => {
                vm.update()
                  vm.notify(vm.$t('event_saved_successfuly'), {
                      color: 'success',
                      icon: 'mdi-check-circle'
                      
                  })
                  vm.dialogLoading(false)
                  vm.closeDialog()
              }).catch(vm.handelError).finally(() => vm.dialogLoading(false))

      }, { persistent: !this.doc, scrollable:true })
    }
  },
  created() {
    if (this.hasRoles('admin')) return this.$router.replace('/dashboard/events')
     this.isAccount = true
     
     var self = this
      interval = setInterval(function () {
          self.now = new Date()
      }, 1000)
  },
  beforeDestroy () {
    if (unsub)
      unsub()
    if (interval)
      clearInterval(interval)
  }
}
</script>

<style>
.pill {
  border-width: 3px !important;
 
  border-radius: 50% !important;
  width: 150px;
  height: 150px !important;
}

.pill .v-chip__content {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}
.pill .v-chip__content .headline {
  font-size: 2rem !important;
  
}
</style>